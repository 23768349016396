import React, { useState } from "react"
import { graphql } from 'gatsby'
import API from '@aws-amplify/api';
import Image from '../components/image-provider'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { WorkWithUsSVG, LoadingSVG } from "../components/svgs"

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
toast.configure()

const ContactPage = ({ data, location }) => {

    const socialCardImage = data.allFile.edges[0].node.childImageSharp.resize

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [website, setWebsite] = useState('');
    const [referral, setReferral] = useState('');
    const [service, setService] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [loading, setLoading] = useState(false);

    const resetState = () => {
        setName('');
        setEmail('');
        setTelephone('');
        setWebsite('');
        setReferral('');
        setService('');
        setAdditionalInfo('');
        setLoading('');
    }
    const notifyMessageSent = () => {
        toast.success(`Your message has been sent!`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
            progressStyle: { backgroundColor: "#DCE1E1" },
            style: { backgroundColor: "rgb(59, 44, 55, 0.9)", color: '#DCE1E1' }
        });
    }

    const handleSendMessage = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const result = await API.post('messengerAPI', '/message', {
                body: {
                    name,
                    email,
                    telephone,
                    website,
                    referral,
                    service,
                    additionalInfo
                }
            })
            console.log({ result });
            resetState();
            setLoading(false);
            notifyMessageSent();
        } catch(err) {
            console.log(err);
            setLoading(false);
        }
    }

    return(
        <Layout>
            <SEO
                title="Contact"
                description="Tell us your vision. We're here to bring your ideas to life. (312)-999-8388 hello@rarechicago.com"
                image={socialCardImage}
                pathname={location.pathname}
            />
            <Image
                fileName="rarechicagomobilecontactbanner.jpg"
                className="laptop:hidden"
                alt="Photograph of an old rotary style telephone. Text `Contact us` overlayed on image."
            />
            <Image
                fileName="rarechicagodesktopcontactbanner.jpg"
                className="hidden laptop:block h-screen min-height"
                alt="Photograph of an old rotary style telephone."
            />

            {/* Desktop Contact Form */}
            <div className="hidden laptop:block absolute top-0 left-0 w-full">
                <div className="max-viewport mx-auto flex items-center h-screen min-height">
                    <div className="bg-gandalfgray px-12 py-10 ml-8 mt-12 rounded-md border-4 border-black border-opacity-50">
                        <div className="w-full px-1">
                            <div className="mx-auto" style={{ maxWidth: '480px' }}>
                                <WorkWithUsSVG />
                            </div>
                        </div>
                        <form
                            id="contact-form"
                            className="w-full mb-0 text-sm"
                            onSubmit={e => handleSendMessage(e)}
                            style={{ maxWidth: '480px' }}
                        >
                            <input
                                className="w-full shadow-xl border-2 border-black border-opacity-30 py-1 px-2 mt-6"
                                aria-label="Full name"
                                name="name"
                                type="text"
                                placeholder="Full Name*"
                                required
                                onChange={e => setName(e.target.value)}
                                value={name}
                            />
                            <input
                                className="w-full shadow-xl border-2 border-black border-opacity-30 py-1 px-2 mt-6"
                                aria-label="Email address"
                                name="email"
                                type="email"
                                placeholder="Email*"
                                required
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                            />
                            <input
                                className="w-full shadow-xl border-2 border-black border-opacity-30 py-1 px-2 mt-6"
                                aria-label="Phone number"
                                name="telephone"
                                type="tel"
                                placeholder="Phone Number*"
                                required
                                onChange={e => setTelephone(e.target.value)}
                                value={telephone}
                            />
                            <input
                                className="w-full shadow-xl border-2 border-black border-opacity-30 py-1 px-2 mt-6"
                                aria-label="Website URL"
                                name="website"
                                type="url"
                                placeholder="Website"
                                onChange={e => setWebsite(e.target.value)}
                                value={website}
                            />
                            <select
                                aria-label="How did you hear about us?"
                                name="referral"
                                className="w-full shadow-xl border-2 border-black border-opacity-30 py-1 px-1 mt-6 bg-white"
                                required
                                onBlur={e => setReferral(e.target.value)}
                                onChange={e => setReferral(e.target.value)}
                                value={referral}
                            >
                                <option value="" disabled defaultValue>How did you hear about us?</option>
                                <option value="Google">Google</option>
                                <option value="Social Media">Social Media</option>
                                <option value="Referral">Referral</option>
                                <option value="Other">Other</option>
                            </select>
                            <select
                                aria-label="Service requested"
                                name="service"
                                className="w-full shadow-xl border-2 border-black border-opacity-30 py-1 px-1 mt-6 bg-white"
                                required
                                onBlur={e => setService(e.target.value)}
                                onChange={e => setService(e.target.value)}
                                value={service}
                            >
                                <option value="" disabled defaultValue>Service</option>
                                <option value="Web Development">Web Development</option>
                                <option value="Digital Marketing">Digital Marketing</option>
                                <option value="Other">Other</option>
                            </select>
                            <textarea
                                aria-label="Additional information"
                                name="additionalInfo"
                                rows="4"
                                placeholder="Additional information or project description..."
                                className="w-full shadow-xl border-2 border-black border-opacity-30 py-1 px-2 mt-6"
                                onChange={e => setAdditionalInfo(e.target.value)}
                                value={additionalInfo}
                            />
                            <button
                                type="submit"
                                form="contact-form"
                                className="bg-ltblue text-white w-2/3 block mx-auto rounded-md mt-8 flex justify-around items-center"
                                style={{ height: '36px' }}
                            >
                                { loading ? <LoadingSVG className="h-4" /> : `Send` }
                            </button>
                        </form>
                    </div>
                </div>

            </div>

            {/* Mobile Contact Form */}
            <div className="flex flex-wrap w-full bg-gandalfgray px-2 pb-8 laptop:hidden">               
                <div className="w-full px-1 mt-12">
                    <div className="mx-auto" style={{ maxWidth: '420px' }}>
                        <WorkWithUsSVG />
                    </div>
                </div>
                <form
                    id="mobile-contact-form"
                    className="w-full mb-0 text-sm mx-auto"
                    onSubmit={e => handleSendMessage(e)}
                    style={{ maxWidth: '420px' }}
                >
                    <input
                        className="w-full shadow-xl border-2 border-black border-opacity-30 py-1 px-2 mt-10"
                        aria-label="Full name"
                        name="name"
                        type="text"
                        placeholder="Full Name*"
                        required
                        onChange={e => setName(e.target.value)}
                        value={name}
                    />
                    <input
                        className="w-full shadow-xl border-2 border-black border-opacity-30 py-1 px-2 mt-6"
                        aria-label="Email address"
                        name="email"
                        type="email"
                        placeholder="Email*"
                        required
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                    />
                    <input
                        className="w-full shadow-xl border-2 border-black border-opacity-30 py-1 px-2 mt-6"
                        aria-label="Phone number"
                        name="telephone"
                        type="tel"
                        placeholder="Phone Number*"
                        required
                        onChange={e => setTelephone(e.target.value)}
                        value={telephone}
                    />
                    <input
                        className="w-full shadow-xl border-2 border-black border-opacity-30 py-1 px-2 mt-6"
                        aria-label="Website URL"
                        name="website"
                        type="url"
                        placeholder="Website"
                        onChange={e => setWebsite(e.target.value)}
                        value={website}
                    />
                    <select
                        aria-label="How did you hear about us?"
                        name="referral"
                        className="w-full shadow-xl border-2 border-black border-opacity-30 py-1 px-1 mt-6 bg-white"
                        required
                        onBlur={e => setReferral(e.target.value)}
                        onChange={e => setReferral(e.target.value)}
                        value={referral}
                    >
                        <option value="" disabled defaultValue>How did you hear about us?</option>
                        <option value="Google">Google</option>
                        <option value="Social Media">Social Media</option>
                        <option value="Referral">Referral</option>
                        <option value="Other">Other</option>
                    </select>
                    <select
                        aria-label="Service requested"
                        name="service"
                        className="w-full shadow-xl border-2 border-black border-opacity-30 py-1 px-1 mt-6 bg-white"
                        required
                        onBlur={e => setService(e.target.value)}
                        onChange={e => setService(e.target.value)}
                        value={service}
                    >
                        <option value="" disabled defaultValue>Service</option>
                        <option value="Web Development">Web Development</option>
                        <option value="Digital Marketing">Digital Marketing</option>
                        <option value="Other">Other</option>
                    </select>
                    <textarea
                        aria-label="Additional information"
                        name="additionalInfo"
                        rows="4"
                        placeholder="Additional information or project description..."
                        className="w-full shadow-xl border-2 border-black border-opacity-30 py-1 px-2 mt-6"
                        onChange={e => setAdditionalInfo(e.target.value)}
                        value={additionalInfo}
                    />
                    <button
                        type="submit"
                        form="mobile-contact-form"
                        className="bg-ltblue text-white w-2/3 block mx-auto rounded-md mt-8 flex justify-around items-center"
                        style={{ height: '36px' }}
                    >
                        { loading ? <LoadingSVG className="h-4" /> : `Send` }
                    </button>
                </form>
            </div>

        </Layout>
    )
}

export default ContactPage;

export const pageQuery = graphql`
    query {
        allFile(filter: {name: {eq: "rarechicago-socialcard-contact"}}) {
        edges {
            node {
            childImageSharp {
                resize(width: 1200) {
                src
                height
                width
                }
            }
            }
        }
        }
    }
`
